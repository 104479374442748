<template>
  <v-dialog
    v-model="clockOutDialogLocal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    :persistent="true"
    content-class="clockout-dialog"
  >
    <v-card tile>
      <v-system-bar color="darken-2" style="height: 40px" class="pl-7 pr-1">
        <span class="text-h6">{{ $t("c_clockout.t_clock_out") }}</span>
        <v-spacer></v-spacer>
      </v-system-bar>
      <v-card-text class="pt-6">
        <v-form ref="clockoutForm" v-model="isValid">
          <v-row>
            <v-col>
              <div class="text-body-1 font-weight-bold">{{ $t("c_clockout.t_witness_safety_incident") }}</div>
            </v-col>
          </v-row>
          <v-row align-content="center">
            <v-col class="d-flex" cols="12">
              <v-radio-group
                class="text-bold mt-0 pt-0 ml-3"
                row
                return-object
                v-model="clockOut.incidentReport"
                :rules="[(v) => !!v || 'Required!']"
              >
                <v-radio :label="$t('c_clockout.t_yes')" value="true"></v-radio>
                <v-radio :label="$t('c_clockout.t_no')" value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <div v-if="activeTimeClock && activeTimeClock.notes && activeTimeClock.notes.length">
            <v-list two-line>
              <span v-for="(notes, index) in activeTimeClock.notes" :key="index">
                <v-list-item>
                  <v-list-item-avatar>
                    <AppUserAvatar :user="user"></AppUserAvatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ notes.note }} </v-list-item-title>
                    <v-list-item-subtitle>{{ formatDateFull(notes.createdOn) }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </v-list>
          </div>
          <div>
            <div class="notes-wrapper">
              <v-textarea
                name="timeclock-note"
                filled
                auto-grow.
                :label="
                  clockOut.incidentReport === 'true'
                    ? $t('c_clockout.t_describe_incident')
                    : $t('c_clockout.t_add_note')
                "
                value=""
                :rules="
                  clockOut.incidentReport === 'true'
                    ? [(v) => !!v || $t('c_clockout.t_val_req_provide_incident_details')]
                    : []
                "
                :hint="
                  clockOut.incidentReport == 'true'
                    ? $t('c_clockout.t_include_incident_details')
                    : $t('c_clockout.t_include_job_notes')
                "
                v-model="clockOut.timeClockNote"
                rows="2"
              ></v-textarea>
            </div>
          </div>

          <v-card-actions class="pl-0">
            <v-btn color="primary" class="mr-4 px-9" @click="validateAndSubmit" x-large :disabled="!isValid">
              {{ $t("t_submit") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="cancelClockOut" small> {{ $t("t_cancel") }} </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Helpers from "@/mixins/helpers";
import { DateTime } from "luxon";
import AppUserAvatar from "@/components/AppUserAvatar.vue";

export default {
  name: "CheckOut",
  components: { AppUserAvatar },

  props: {
    clockOutDialog: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [Helpers],
  model: {
    prop: "clockOutDialog",
    event: "clockOutDialogChange",
  },

  computed: {
    ...mapGetters("timeClock", ["activeTimeClock"]),
    ...mapGetters("user", ["user"]),
    ...mapState("location", ["geoLocationCoords"]),

    clockOutDialogLocal: {
      get: function () {
        return this.clockOutDialog;
      },
      set: function (value) {
        this.$emit("clockOutDialogChange", value);
      },
    },
  },
  watch: {
    clockOutDialog: function (open) {
      if (open) {
        this.getLocation();
        this.resetClockOut();
      }
    },
  },
  data() {
    return {
      clockOut: {
        id: null,
        clockEnd: null,
        clockEndLatLng: null,
        clockEndLatLngMethod: null,
        fitForDuty: null,
        incidentReport: null,
        timeClockNote: "",
      },
      isValid: false,
    };
  },
  methods: {
    ...mapActions("timeClock", ["doClockOut", "getReasons", "saveTimeClockNote", "getTimeClocks"]),
    validateAndSubmit() {
      this.$refs.clockoutForm.validate();
      if (this.isValid && this.activeTimeClock) {
        this.submitClockOut();
      } else {
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_clock_out_has_issue"),
          color: "warning",
          timeout: "",
        });
      }
    },
    submitClockOut() {
      const geoISO = this.geoISO6709format(this.geoLocationCoords);
      this.clockOut.id = this.activeTimeClock.id;
      this.clockOut.clockEnd = DateTime.now().toUTC().toISO();
      this.clockOut.clockEndLatLng = geoISO ? geoISO : "";
      this.clockOut.clockEndLatLngMethod = this.getDeviceInfo();

      this.doClockOut(this.clockOut)
        .then((timeClock) => {
          this.clockOutDialogLocal = false;
          this.$emit("clockOutComplete", timeClock);
        })
        .catch((err) => {
          let clockedOutCheck = err.some((e) => e.code.includes("alreadyClockedOut"));
          if (clockedOutCheck) {
            this.getTimeClocks();
            this.clockOutDialogLocal = false;
          }
        });
    },

    cancelClockOut() {
      this.$emit("cancelClockOut");
    },
    resetClockOut() {
      if (this.$refs.clockoutForm) {
        this.$refs.clockoutForm.reset();
      }
    },
  },
};
</script>
<style lang="scss">
.clockout-dialog {
  height: calc(100% - 120px) !important;
  bottom: 0;
  top: initial !important;
  border-top: 5px solid #777;
  background: #aaa;
}
.notes-wrapper {
  .timeclock-note {
    padding: 15px;
    background: rgba(255, 255, 255, 0.05);
    margin: 15px 0;
  }
}
</style>
