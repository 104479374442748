<template>
  <div>
    <div v-if="assignments?.length < 1 && parent == 'timeclocks'"></div>
    <div v-else>
      <slot name="header" :assignments="assignments"></slot>
      <v-list
        class="bg-main"
        :class="parent == 'timeclocks' ? 'shim-item' : ''"
        flat
        two-line
        :style="parent == 'timeclocks' && $vuetify.lightmode ? 'background:#f9f9f9 !important' : ''"
      >
        <div class="transparent mx-1" v-if="showFilters && $online">
          <v-row>
            <v-col cols="12" sm="5" :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'">
              <v-text-field
                class="mx-3 mb-5"
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('c_formassignments.t_find_assignment')"
                v-model="assignmentsSearch"
                @input="searchAssignments"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="7" class="pr-4" :class="$vuetify.breakpoint.smAndUp ? '' : 'pt-0'">
              <v-row>
                <v-col cols="6" class="pr-2">
                  <v-select
                    :class="$vuetify.breakpoint.smAndUp ? '' : 'ml-6'"
                    :items="[
                      { text: $t('c_formassignments.t_all_types'), val: '' },
                      { text: $t('c_formassignments.t_form'), val: 'form' },
                      { text: $t('c_formassignments.t_checklist'), val: 'checklist' },
                      { text: $t('c_formassignments.t_audit'), val: 'audit' },
                    ]"
                    :label="$t('c_formassignments.t_type')"
                    item-text="text"
                    item-value="val"
                    v-model="assignmentsTag"
                    @change="loadAssignments(true)"
                  ></v-select>
                </v-col>

                <v-col cols="6" md="4" sm="6" class="text-right pt-5">
                  <div class="d-inline-flex pr-1">
                    <v-checkbox
                      class="py-0 pt-1 mt-1"
                      @change="loadAssignments(true)"
                      v-model="assignmentsUrgentOnly"
                      :label="$t('c_formassignments.t_urgent')"
                      color="red darken-3"
                      value="submitted"
                      hide-details
                    ></v-checkbox>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          large
                          @click="
                            assignmentsAscending = !assignmentsAscending;
                            loadAssignments(true);
                          "
                          v-model="assignmentsAscending"
                          class="ml-2 mr-2 mt-0 pt-0"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{
                            !assignmentsAscending ? "mdi-sort-calendar-ascending" : "mdi-sort-calendar-descending"
                          }}</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          assignmentsAscending
                            ? $t("c_formassignments.t_descending")
                            : $t("c_formassignments.t_ascending")
                        }}</span
                      >
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col cols="2" class="pt-4 pr-2 text-right" v-if="$vuetify.breakpoint.mdAndUp">
                  <span>
                    <v-btn icon class="ma-0 pa-0 mt-2 mr-2" @click="$emit('refresh')" v-if="$platform == 'web'"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <div
          v-for="(item, idx) in showDateFormat
            ? formatDateList(assignments, 'availableOn', showWeekHeader)
            : assignments"
          :key="idx"
        >
          <div class="form-item-wrap">
            <div
              class="my-3 mt-4 pr-4 bg-header subtitle-2"
              :style="{
                'color: rgba(255, 255, 255, 0.5)': $vuetify.theme.dark,
              }"
              v-if="item.weekStart"
            >
              {{ item.weekStart }}
              <span v-if="item.weekEnd">-</span>
              {{ item.weekEnd }}
            </div>

            <div class="my-3 pl-4 subtitle-2" v-if="item.day && showDateFormat">{{ item.day }}</div>

            <v-list-item
              class="form-item rounded mx-2 my-2"
              :class="{ 'grey darken-4': $vuetify.theme.dark }"
              :key="'assignment-' + item.id + '-' + idx"
              :to="{
                name: item.submissionId ? 'submission' : 'assignment',
                params: {
                  id: item.submissionId ? item.submissionId : item.id,
                  tab: tab,
                  type: type,
                },
              }"
            >
              <FormIcon ref="formIcon" :item="item" :parent="'assignments'" />

              <FormListItem ref="formListItem" :item="item" :parent="'assignments'" />
            </v-list-item>
          </div>
        </div>
      </v-list>

      <div v-if="ready">
        <h4 class="pa-4 pt-0" v-if="assignments.length < 1 && parent != 'timeclocks'">
          {{ $t("c_formassignments.t_no_assignments") }} {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
        </h4>
        <slot name="loadmore">
          <v-btn
            v-if="showLoadMoreAssignments && assignments && assignments.length > 0"
            class="mx-3 mb-3"
            @click="loadMoreAssignments"
            >{{ $t("t_load_more") }}</v-btn
          >
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import { DateTime } from "luxon";
import FormIcon from "@/components/forms/FormIcon.vue";
import FormListItem from "@/components/forms/FormListItem.vue";
import debounce from "lodash/debounce";

export default {
  name: "FormAssignments",
  components: { FormIcon, FormListItem },
  props: {
    tab: {
      type: Number,
    },
    type: {
      type: String,
      default: "assignments",
    },
    parent: {
      type: String,
      default: "forms",
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showWeekHeader: {
      type: Boolean,
      default: true,
    },
    showDateFormat: {
      type: Boolean,
      default: true,
    },
    hasExpiresOn: {
      type: Boolean,
      default: null,
    },
    takeNum: {
      type: Number,
      default: 20,
    },
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("user", ["user"]),
  },
  watch: {
    $online(n) {
      if (n && this.assignments?.length == 0) {
        this.loadAssignments();
      }
    },
  },
  data() {
    return {
      ready: false,
      assignments: [],
      assignmentsSearch: "",
      assignmentsOrderByField: "AssignedOn",
      assignmentsAscending: false,
      assignmentsSkipNum: 0,
      assignmentsTakeNum: this.takeNum,
      assignmentsTag: "",
      assignmentsUrgentOnly: null,
      showLoadMoreAssignments: false,
    };
  },
  methods: {
    ...mapActions("forms", ["getFormAssignments"]),
    loadAssignments(reload = false) {
      if (!this.$online) {
        this.ready = true;
        return;
      }
      this.ready = false;
      if (reload) {
        this.assignments = [];
        this.assignmentsSkipNum = 0;
      }
      this.showLoadBar();
      let assignmentParams = {
        "paging.orderbyfield": this.assignmentsOrderByField,
        "paging.ascending": this.assignmentsAscending,
        "paging.skipnum": this.assignmentsSkipNum,
        "paging.takenum": this.assignmentsTakeNum,
      };
      if (this.assignmentsSearch) {
        assignmentParams.contentWildcardSearch = this.assignmentsSearch;
      }
      if (this.assignmentsTag) {
        assignmentParams.tagswildcard = this.assignmentsTag.toLowerCase();
      }
      assignmentParams.hasSubmittedSubmission = false;
      assignmentParams.urgent = this.assignmentsUrgentOnly ? true : null;
      assignmentParams.hasDraftedSubmission = null;
      assignmentParams.availableBefore = DateTime.now().toISO();
      assignmentParams.HasExpiresOn = this.hasExpiresOn;
      this.getFormAssignments(assignmentParams).then((a) => {
        let assignments = a.data;
        if (assignments) {
          assignments.forEach((a) => {
            this.assignments.push(a);
          });
          if (a.meta?.endOfResults) {
            this.showLoadMoreAssignments = false;
          } else {
            this.showLoadMoreAssignments = true;
          }
        }
        this.hideLoadBar();
        this.ready = true;
      });
    },
    loadMoreAssignments() {
      this.assignmentsSkipNum = this.assignments.length;
      this.loadAssignments();
    },
    searchAssignments: debounce(function () {
      this.loadAssignments(true);
    }, 500),
  },
  mounted() {
    this.loadAssignments(true);
  },
};
</script>
