<template>
  <v-list-item-content>
    <v-list-item-subtitle class="my-0 py-0" style="max-height: 26px" v-if="item.submittedOn">
      <div class="overline" style="line-height: inherit; position: relative">
        <span v-if="!item.reviewedOn" style="top: -1px; position: relative">
          <v-icon small color="orange darken-1"> mdi-eye-off </v-icon>
        </span>
        <span v-if="item.reviewedOn" style="top: -1px; position: relative">
          <v-icon small color="green darken-1"> mdi-eye </v-icon>
        </span>
        <span
          :class="item.reviewedOn ? 'green--text text-darken-1' : 'orange--text text--darken-2'"
          v-if="item.submittedOn"
        >
          {{ item.reviewedOn ? "" + formatDate(item.reviewedOn) : $t("c_formsubmissions.t_pending_review") }}
        </span>
      </div>
    </v-list-item-subtitle>
    <v-list-item-subtitle class="my-0 py-0" style="max-height: 26px" v-else>
      <div class="overline" style="line-height: inherit">
        <span v-if="item.urgent" class="red--text text--darken-2" :class="item.submissionId ? 'mr-3' : ''">{{
          $t("c_formassignments.t_urgent")
        }}</span>
        <span v-if="item.submissionId" class="blue--text mr-3">{{ $t("c_formassignments.t_started") }}</span>
      </div>
    </v-list-item-subtitle>
    <v-list-item-title class="text-subtitle-1 text-wrap pt-1">
      {{ item.revisionName ? item.revisionName : item.name }}
    </v-list-item-title>
    <v-list-item-subtitle class="py-1" v-if="item.formDescription && parent != 'timeclock'">
      {{ item.formDescription }}
    </v-list-item-subtitle>
    <v-list-item-subtitle class="py-1" v-else-if="item.description">
      {{ item.description }}
    </v-list-item-subtitle>
    <v-list-item-subtitle>
      <span class="green--text text--lighten-1" v-if="item.submission && item.submission.submittedOn">
        Submitted
        {{ formatDateTime(item.submission.submittedOn) }}
      </span>
      <span class="blue--text text--lighten-1" v-else-if="item.submission && item.submission.startedOn">
        {{ $t("t_started") }}
        {{ formatDateTime(item.submission.startedOn) }}
      </span>
      <span
        class="orange--text d-inline-block"
        :class="isDateExpired(item.expiresOn) ? 'text--darken-3' : ''"
        v-if="item.expiresOn && ((item.submission && !item.submission.submittedOn) || !item.submission)"
      >
        {{ isDateExpired(item.expiresOn) ? "! Due" : "Due" }}
        {{ formatDateTime(item.expiresOn) }}
      </span>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "FormListItem",
  mixins: [Helpers],
  props: {
    item: {
      type: Object,
    },
    parent: {
      type: String,
    },
  },
};
</script>
