<template>
  <div class="bg-app timeclocks" :class="{ dark: $vuetify.theme.dark }">
    <VuePullRefresh
      :key="pullKey"
      :on-refresh="appRefresh"
      :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
    >
      <v-container class="timeclocks-wrapper pt-0" fluid>
        <v-row>
          <v-col>
            <div style="margin-left: 3px" :class="$vuetify.breakpoint.mobile ? 'pt-2' : 'pt-3'">
              <clock-timer ref="clockTimer">
                <template v-slot:refresh>
                  <v-btn
                    icon
                    class="ml-2 float-right btn-refresh"
                    style="margin-top: 5px"
                    :class="{ rotate: loading }"
                    @click="appRefresh"
                    ><v-icon class="icon-grey">mdi-refresh</v-icon></v-btn
                  >
                </template>
              </clock-timer>
            </div>
          </v-col>
        </v-row>
        <time-clocks
          ref="timeClocks"
          :fetchTimeClocks="fetchTimeClocks"
          @startClockPreset="startClockPreset"
        ></time-clocks>
      </v-container>
    </VuePullRefresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Helpers from "@/mixins/helpers";
import VuePullRefresh from "vue-pull-refresh";
import TimeClocks from "@/components/timeclock/TimeClocks.vue";
import ClockTimer from "@/components/timeclock/ClockTimer.vue";

export default {
  name: "TimeClock",
  components: {
    TimeClocks,
    ClockTimer,
    VuePullRefresh,
  },
  metaInfo() {
    return {
      title: "Timeclock",
    };
  },
  mixins: [Helpers],
  computed: {
    ...mapState("loader", ["loading"]),
  },
  data: () => ({
    fetchTimeClocks: false,
    pullIcon: "",
    pullKey: 1,
  }),
  methods: {
    startClockPreset(id) {
      this.$refs.clockTimer.startClock(id);
    },
    appRefresh() {
      this.$refs.timeClocks.loadTimeClocks();
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
  },
  mounted() {
    if (this.$route.params.fetchTimeClocks) {
      this.fetchTimeClocks = true;
    }
  },
};
</script>
