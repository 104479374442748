<template>
  <div class="timeclock-timer" :class="{ dark: $vuetify.theme.dark }">
    <v-container class="timeclocks-timer-wrapper pt-1 pl-0" fluid>
      <v-row>
        <v-col>
          <v-btn
            large
            value="justify"
            class="font-weight-bold btn-time"
            :class="{ 'time-active': (clockInDialog && !timeClockDuration) || timeClockDuration }"
            @click="timeClockDuration ? clockOut() : startClock()"
          >
            <v-icon v-if="clockInDialog && !timeClockDuration" left class="icon-pull-loading" style="font-size: 18px"
              >mdi-loading</v-icon
            >
            <v-icon v-else-if="timeClockDuration" left color="red lighten-4" style="font-size: 34px"> mdi-stop </v-icon>
            <v-icon left v-else color="lime lighten-4" style="font-size: 34px"> mdi-play </v-icon>
            <span class="ml-2">{{
              (clockInDialog && !timeClockDuration) || timeClockDuration
                ? $t("v_timeclock.t_stop")
                : $t("v_timeclock.t_start")
            }}</span>
          </v-btn>
          <div class="d-inline-block time-duration-wrap">
            <span class="ml-5 time-duration">{{ timeClockDuration ? timeClockDuration : "0:00:00" }}</span>
          </div>
          <slot name="refresh" v-if="$platform == 'web' && !$vuetify.breakpoint.mobile && !clockInDialog"></slot>
        </v-col>
      </v-row>
      <ClockIn
        ref="clockIn"
        :clockInDialog="clockInDialog"
        :clockStart="clockStart"
        @cancelClockIn="cancelClockIn"
        @clockInComplete="clockInComplete"
        v-model="clockInDialog"
      />
      <ClockOut
        ref="clockOut"
        :clockOutDialog="clockOutDialog"
        @cancelClockOut="cancelClockOut"
        @clockOutComplete="clockOutComplete"
        v-model="clockOutDialog"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { DateTime } from "luxon";
import Helpers from "@/mixins/helpers";
import ClockIn from "@/components/timeclock/ClockIn";
import ClockOut from "@/components/timeclock/ClockOut";

export default {
  name: "ClockTimer",
  components: {
    ClockIn,
    ClockOut,
  },
  metaInfo() {
    return {
      title: this.timeClockDuration ? this.timeClockDuration : "Timeclock",
    };
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("timeClock", ["timeClocks", "timeClockDuration"]),
    ...mapState("loader", ["loading"]),
  },
  data: () => ({
    clockStart: null,
    loadingDuration: false,
    clockInDialog: false,
    clockOutDialog: false,
  }),
  methods: {
    ...mapActions("timeClock", ["getTimeClocks", "setTimeClockDuration"]),
    async startClock(id = null) {
      this.loadingDuration = true;
      if (id) {
        this.$refs.clockIn.fillClockPreset(id);
      }
      this.clockStart = DateTime.now().toUTC().toISO();
      //simple if refresh insurance... as vuex it would need it's own localstorage module since the timeclock doesn't play nice as storage:
      localStorage.setItem("clockStartPending", this.clockStart);
      if (this.clockStart) {
        this.setTimeClockDuration(this.clockStart);
        setTimeout(() => {
          this.loadingDuration = false;
        }, 1000);
      }
      this.clockInDialog = true;
    },
    async clockOut() {
      if (this.clockInDialog) {
        return false;
      }
      this.clockOutDialog = true;
    },
    async cancelClockIn() {
      localStorage.removeItem("clockStartPending");
      this.setTimeClockDuration(null);
      this.clockInDialog = false;
    },
    async clockInComplete(timeClock) {
      localStorage.removeItem("clockStartPending");
      this.timeClocks.unshift(timeClock);
    },
    clockOutComplete(timeClock) {
      this.clockStart = null;
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_clocked_out"),
        color: "success",
        timeout: "",
      });
      if (timeClock.id) {
        this.$set(this.timeClocks, 0, timeClock);
      } else {
        this.getTimeClocks();
      }
    },
    async cancelClockOut() {
      this.clockOutDialog = false;
    },
  },
  mounted() {
    //clockStartPending catches case of refresh/reload after clockin but haven't checkedin
    let clockStartPending = localStorage.getItem("clockStartPending");
    if (clockStartPending) {
      if (!this.clockStart) {
        this.clockStart = clockStartPending;
        this.setTimeClockDuration(clockStartPending);
        this.$refs.clockIn.init();
        this.clockInDialog = true;
      } else {
        localStorage.removeItem("clockStartPending");
      }
    }
    if (this.activeTimeClock) {
      this.clockStart = this.activeTimeClock.clockStart;
    }
    this.getTimeClocks();
  },
};
</script>
<style lang="scss">
.timeclock-timer {
  .timeclocks-timer-wrapper {
    .time-duration-wrap {
      position: relative;
      top: 2px;
    }
    .time-duration {
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 500;
    }
    .btn-time-wrap:hover .btn-duration {
      box-shadow: none !important;
    }

    .btn-duration {
      &.v-btn {
        opacity: 1 !important;
        font-size: 16px !important;
        padding-left: 34px !important;
        min-width: 136px !important;
        span {
          display: inline-block !important;
          text-align: left;
        }
        &:hover::before {
          opacity: 0;
        }
      }
    }

    .btn-time {
      background: #00992b !important;
      color: #fff !important;

      opacity: 1 !important;
      font-size: 16px !important;
      padding: 0 24px !important;
      min-width: 140px !important;
      &:active {
        box-shadow: none !important;
      }
      &.time-active {
        background: #be2929 !important;
      }
    }
    .btn-refresh {
      margin-top: 3px;
    }
    .theme--dark {
      .btn-time-wrap:hover .btn-duration {
        background: #2e2701 !important;
      }
      .btn-duration {
        &.v-btn {
          background: #2e2701;
          color: #fff !important;
          border: #be2929 !important;

          &:disabled {
            color: #fff !important;
          }
          span {
            color: #f1f1f1 !important;
          }
        }
      }

      .btn-time {
        background: #00992b !important;
        &:active {
          background: #fc2e32 !important;
        }
      }
    }
  }
}
</style>
