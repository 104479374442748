<template>
  <v-list-item-icon class="form-icon mr-4" :class="parent">
    <v-icon v-if="item.tags?.indexOf('form') != -1" color="secondary" large>mdi-clipboard</v-icon>
    <v-icon v-else-if="item.tags?.indexOf('audit') != -1" color="secondary" large>mdi-clipboard-text-search</v-icon>
    <v-icon v-else-if="item.tags?.indexOf('checklist') != -1" class="pa-0 ma-0" color="secondary" large
      >mdi-clipboard-text</v-icon
    >
    <v-icon v-else color="secondary" large>mdi-text-box</v-icon>
    <v-badge
      v-if="parent == 'assignments'"
      :color="item.submissionId ? 'blue' : 'orange darken-2'"
      :icon="item.submissionId ? 'mdi-progress-clock' : 'mdi-clock-outline'"
      bordered
      overlap
      top
      offset-x="15"
      offset-y="10"
    ></v-badge>
  </v-list-item-icon>
</template>
<script>
export default {
  name: "FormIcon",
  props: {
    item: {
      type: Object,
    },
    parent: {
      type: String,
    },
  },
};
</script>
