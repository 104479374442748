var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.assignments?.length < 1 && _vm.parent == 'timeclocks')?_c('div'):_c('div',[_vm._t("header",null,{"assignments":_vm.assignments}),_c('v-list',{staticClass:"bg-main",class:_vm.parent == 'timeclocks' ? 'shim-item' : '',style:(_vm.parent == 'timeclocks' && _vm.$vuetify.lightmode ? 'background:#f9f9f9 !important' : ''),attrs:{"flat":"","two-line":""}},[(_vm.showFilters && _vm.$online)?_c('div',{staticClass:"transparent mx-1"},[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndUp ? '' : 'pb-0',attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{staticClass:"mx-3 mb-5",attrs:{"clearable":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('c_formassignments.t_find_assignment')},on:{"input":_vm.searchAssignments},model:{value:(_vm.assignmentsSearch),callback:function ($$v) {_vm.assignmentsSearch=$$v},expression:"assignmentsSearch"}})],1),_c('v-col',{staticClass:"pr-4",class:_vm.$vuetify.breakpoint.smAndUp ? '' : 'pt-0',attrs:{"cols":"12","sm":"7"}},[_c('v-row',[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('v-select',{class:_vm.$vuetify.breakpoint.smAndUp ? '' : 'ml-6',attrs:{"items":[
                    { text: _vm.$t('c_formassignments.t_all_types'), val: '' },
                    { text: _vm.$t('c_formassignments.t_form'), val: 'form' },
                    { text: _vm.$t('c_formassignments.t_checklist'), val: 'checklist' },
                    { text: _vm.$t('c_formassignments.t_audit'), val: 'audit' },
                  ],"label":_vm.$t('c_formassignments.t_type'),"item-text":"text","item-value":"val"},on:{"change":function($event){return _vm.loadAssignments(true)}},model:{value:(_vm.assignmentsTag),callback:function ($$v) {_vm.assignmentsTag=$$v},expression:"assignmentsTag"}})],1),_c('v-col',{staticClass:"text-right pt-5",attrs:{"cols":"6","md":"4","sm":"6"}},[_c('div',{staticClass:"d-inline-flex pr-1"},[_c('v-checkbox',{staticClass:"py-0 pt-1 mt-1",attrs:{"label":_vm.$t('c_formassignments.t_urgent'),"color":"red darken-3","value":"submitted","hide-details":""},on:{"change":function($event){return _vm.loadAssignments(true)}},model:{value:(_vm.assignmentsUrgentOnly),callback:function ($$v) {_vm.assignmentsUrgentOnly=$$v},expression:"assignmentsUrgentOnly"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-2 mt-0 pt-0",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.assignmentsAscending = !_vm.assignmentsAscending;
                          _vm.loadAssignments(true);}},model:{value:(_vm.assignmentsAscending),callback:function ($$v) {_vm.assignmentsAscending=$$v},expression:"assignmentsAscending"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(!_vm.assignmentsAscending ? "mdi-sort-calendar-ascending" : "mdi-sort-calendar-descending"))])],1)]}}],null,false,763429738)},[_c('span',[_vm._v(" "+_vm._s(_vm.assignmentsAscending ? _vm.$t("c_formassignments.t_descending") : _vm.$t("c_formassignments.t_ascending")))])])],1)]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pt-4 pr-2 text-right",attrs:{"cols":"2"}},[_c('span',[(_vm.$platform == 'web')?_c('v-btn',{staticClass:"ma-0 pa-0 mt-2 mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('refresh')}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()],1)]):_vm._e()],1)],1)],1)],1):_vm._e(),_vm._l((_vm.showDateFormat
          ? _vm.formatDateList(_vm.assignments, 'availableOn', _vm.showWeekHeader)
          : _vm.assignments),function(item,idx){return _c('div',{key:idx},[_c('div',{staticClass:"form-item-wrap"},[(item.weekStart)?_c('div',{staticClass:"my-3 mt-4 pr-4 bg-header subtitle-2",style:({
              'color: rgba(255, 255, 255, 0.5)': _vm.$vuetify.theme.dark,
            })},[_vm._v(" "+_vm._s(item.weekStart)+" "),(item.weekEnd)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" "+_vm._s(item.weekEnd)+" ")]):_vm._e(),(item.day && _vm.showDateFormat)?_c('div',{staticClass:"my-3 pl-4 subtitle-2"},[_vm._v(_vm._s(item.day))]):_vm._e(),_c('v-list-item',{key:'assignment-' + item.id + '-' + idx,staticClass:"form-item rounded mx-2 my-2",class:{ 'grey darken-4': _vm.$vuetify.theme.dark },attrs:{"to":{
              name: item.submissionId ? 'submission' : 'assignment',
              params: {
                id: item.submissionId ? item.submissionId : item.id,
                tab: _vm.tab,
                type: _vm.type,
              },
            }}},[_c('FormIcon',{ref:"formIcon",refInFor:true,attrs:{"item":item,"parent":'assignments'}}),_c('FormListItem',{ref:"formListItem",refInFor:true,attrs:{"item":item,"parent":'assignments'}})],1)],1)])})],2),(_vm.ready)?_c('div',[(_vm.assignments.length < 1 && _vm.parent != 'timeclocks')?_c('h4',{staticClass:"pa-4 pt-0"},[_vm._v(" "+_vm._s(_vm.$t("c_formassignments.t_no_assignments"))+" "+_vm._s(!_vm.$online ? " : " + _vm.$t("t_no_network_connection") : "")+" ")]):_vm._e(),_vm._t("loadmore",function(){return [(_vm.showLoadMoreAssignments && _vm.assignments && _vm.assignments.length > 0)?_c('v-btn',{staticClass:"mx-3 mb-3",on:{"click":_vm.loadMoreAssignments}},[_vm._v(_vm._s(_vm.$t("t_load_more")))]):_vm._e()]})],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }