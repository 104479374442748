<template>
  <v-dialog
    v-model="clockInDialogLocal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    :persistent="true"
    content-class="clockin-dialog"
  >
    <v-card tile>
      <v-system-bar color="darken-2" style="height: 40px" class="pl-7 pr-1">
        <span class="text-h6">{{ $t("c_clockin.t_clock_in") }}</span>
        <v-spacer></v-spacer>
      </v-system-bar>
      <v-card-text class="pt-6">
        <v-form ref="clockinForm" v-model="isValid">
          <v-select
            v-model="clockIn.timeCategoryId"
            :items="timeCategories"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || $t('c_clockin.t_val_req_category')]"
            :label="$t('c_clockin.t_category')"
            required
          >
            <v-icon slot="prepend"> mdi-account-hard-hat </v-icon>
          </v-select>
          <v-select
            v-if="jobs && jobs.length"
            v-model="clockIn.jobId"
            :items="jobs"
            item-text="jobName"
            item-value="jobId"
            :rules="[(v) => !!v || $t('c_clockin.t_val_req_job')]"
            :label="$t('c_clockin.t_job_worksite')"
            required
            @input="selectJob"
          >
            <v-icon slot="prepend"> mdi-map-marker </v-icon>
          </v-select>
          <v-select
            v-if="costCodes && costCodes.length"
            v-model="clockIn.costCodeId"
            :items="costCodes"
            item-text="costCodeDescription"
            item-value="costCodeId"
            :rules="[(v) => !!v || $t('c_clockin.t_val_req_costcode')]"
            :label="$t('c_clockin.t_cost_code')"
            :required="costCodes && costCodes.length > 0"
          >
            <v-icon slot="prepend">mdi-cash-multiple </v-icon>
            <template slot="selection" slot-scope="data">
              {{ data.item.costCodeCode }} :
              {{ data.item.costCodeDescription }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.costCodeCode }} :
              {{ data.item.costCodeDescription }}
            </template>
          </v-select>
          <v-list two-line>
            <v-list-item class="px-0">
              <v-list-item-action>
                <v-radio-group
                  v-model="clockIn.fitForDuty"
                  column
                  :rules="[(v) => !!v || $t('c_clockin.t_val_req_fit_for_duty')]"
                  required
                >
                  <v-radio value="1">
                    <template v-slot:label>
                      <div class="ml-4">
                        <span class="font-weight-bold">{{ $t("c_clockin.t_fit_for_duty") }}</span
                        ><br />
                        <span>{{ $t("c_clockin.t_fit_for_duty_agreement_now") }}</span>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="0" class="mt-3">
                    <template v-slot:label>
                      <div class="ml-4">
                        <span class="font-weight-bold">{{ $t("c_clockin.t_other") }}</span
                        ><br />
                        <span v-if="clockIn.fitForDuty === '0'">{{ $t("c_clockin.t_explain_with_comment") }}</span>
                        <span v-else>{{ $t("c_clockin.t_do_you_have_factors_affecting_duties") }}</span>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-textarea
            class="mt-3"
            name="input-7-1"
            filled
            auto-grow
            :label="
              clockIn.fitForDuty === '0' ? $t('c_clockin.t_elaborate_your_ability_duties') : $t('c_clockin.t_comments')
            "
            value=""
            :hint="$t('c_clockin.t_comments_hint')"
            rows="2"
            row-height="15"
            v-model="clockIn.timeClockNote"
            :rules="
              clockIn.fitForDuty === '0'
                ? [(v) => !!v || $t('c_clockin.t_val_req_provide_not_fit_for_duty_comment')]
                : []
            "
          >
            <v-icon slot="prepend"> mdi-message-reply </v-icon>
          </v-textarea>
          <v-card-actions class="pl-0">
            <v-btn :disabled="!isValid" color="primary" class="mr-4 px-9" @click="validateAndSubmit" x-large>
              {{ $t("t_submit") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="cancelClockIn" small> {{ $t("t_cancel") }} </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Helpers from "@/mixins/helpers";

export default {
  name: "CheckIn",
  mixins: [Helpers],
  props: {
    clockInDialog: {
      default: false,
      type: Boolean,
    },
    clockStart: {
      type: String,
    },
  },
  model: {
    prop: "clockInDialog",
    event: "clockInDialogChange",
  },
  computed: {
    ...mapState("location", ["geoLocationCoords"]),

    clockInDialogLocal: {
      get: function () {
        return this.clockInDialog;
      },
      set: function (value) {
        this.$emit("clockInDialogChange", value);
      },
    },
  },
  watch: {
    clockInDialog: function (open) {
      if (open) {
        this.init();
      }
    },
  },
  data() {
    return {
      jobs: [],
      timeCategories: [],
      costCodes: [],
      clockIn: {
        jobId: null,
        jobName: "",
        costCodeId: null,
        timeCategoryId: null,
        clockStart: null,
        clockStartReasonId: null,
        clockStartLatLng: "",
        clockStartLatLngMethod: "",
        timeClockNote: "",
        fitForDuty: false,
        incidentReport: false,
      },
      fitForDutyNoteRules: [
        (v) => !!v || "A note is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      isValid: false,
    };
  },
  methods: {
    ...mapActions("timeClock", [
      "getUserJobs",
      "getCategories",
      "getJobCostCodes",
      "doClockIn",
      "getTimeClock",
      "getTimeClocks",
    ]),
    async submitClockIn() {
      let geoISO = this.geoISO6709format(this.geoLocationCoords);

      //Offline support....
      this.clockIn.timeCategoryName = this.timeCategories?.find((cat) => cat.id == this.clockIn.timeCategoryId).name;
      this.clockIn.costCodeCode = this.costCodes?.find(
        (cost) => cost.costCodeId === this.clockIn.costCodeId,
      )?.costCodeCode;
      this.clockIn.jobName = this.jobs.find((job) => job.jobId == this.clockIn.jobId)?.jobName;
      this.clockIn.clockStart = this.clockStart;
      this.clockIn.clockStartLatLng = geoISO ? geoISO : "";
      this.clockIn.clockStartLatLngMethod = this.getDeviceInfo();

      await this.doClockIn(this.clockIn)
        .then((timeClock) => {
          this.$emit("clockInComplete", timeClock);
          this.clockInDialogLocal = false;
          this.responseMessage("c_snackbar.t_success_clocked_in", 2000);
        })
        .catch((err) => {
          let clockedInCheck = err.some((e) => e.code.includes("alreadyClockedIn"));
          if (clockedInCheck) {
            this.getTimeClocks();
            this.clockInDialogLocal = false;
          }
        });
    },
    cancelClockIn() {
      this.resetClockIn();
      this.$emit("cancelClockIn");
    },
    fillClockPreset(id) {
      this.getTimeClock(id).then((clock) => {
        if (clock) {
          this.clockIn.timeCategoryId = clock.timeCategoryId;
          this.clockIn.jobId = clock.jobId;
          this.selectJob();
          this.clockIn.costCodeId = clock.costCodeId;
        }
      });
    },
    validateAndSubmit() {
      this.$refs.clockinForm.validate();
      if (this.isValid) {
        this.submitClockIn();
      } else {
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_clock_out_has_issue"),
          color: "warning",
          timeout: "",
        });
      }
    },
    resetClockIn() {
      Object.assign(this.$data, this.$options.data.apply(this));
      if (this.$refs.clockinForm) {
        this.$refs.clockinForm.reset();
      }
      this.clockIn = {
        jobId: null,
        jobName: "",
        costCodeId: null,
        timeCategoryId: null,
        clockStart: null,
        clockStartReasonId: null,
        clockStartLatLng: "",
        clockStartLatLngMethod: "",
        timeClockNote: "",
        fitForDuty: false,
        incidentReport: false,
        costCodeCode: "",
        timeCategoryName: "",
      };
    },
    selectJob() {
      this.getJobCostCodes({ jobId: this.clockIn.jobId }).then((response) => {
        this.costCodes = response;
      });
    },
    init() {
      this.resetClockIn();
      this.location = this.getLocation();
      const params = { userId: this.$store.state.user.user.userId };
      this.getCategories().then((response) => {
        this.timeCategories = response;
      });
      this.getUserJobs(params).then((response) => {
        this.jobs = response;
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
.clockin-dialog {
  height: calc(100% - 120px) !important;
  bottom: 0;
  top: initial !important;
  border-top: 5px solid #777;
  background: #aaa;
}
</style>
